import React, { useState, useContext } from "react";

import { Input, notification } from "antd";
import {
  UserOutlined,
  RobotOutlined,
  DeleteOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { webAPIs } from "../config/webAPIs";
import ReactMarkdown from "react-markdown";
import { AuthContext } from "../AuthContext";
import axiosInstance from "../config/axiosInstance";

const { TextArea } = Input;

const Dashboard = () => {
  const {
    message,
    handleResetMessage,
    files,
    setMessage,
    adminPrompt,
    user,
    checked,
    setLoading,
  } = useContext(AuthContext);
  const [question, setQuestion] = useState("");

  const handleKeyUp = async (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      await sendRequest();
    }
  };

  const sendRequest = async () => {
    window.scrollTo(0, 99999999);
    setLoading(true);
    if (question.trim().length > 0) {
      if (files && files.length > 0) {
        try {
          setMessage([
            ...message,
            {
              role: "user",
              content: question,
            },
          ]);
          setQuestion("");
          const response = await axiosInstance.post(
            webAPIs.chat,
            {
              prompt: question,
              systemPrompt: adminPrompt,
              checked: checked,
              temp: 0,
            },
            {
              timeout: 1000000,
            }
          );
          setMessage([
            ...message,
            {
              role: "user",
              content: question,
            },
            {
              role: "assistant",
              content: response.data.result,
            },
          ]);
        } catch (error) {
          notification.error({
            description: error.response.data.message || "Server Error",
            duration: 2,
          });
        }
      } else {
        notification.warning({
          description: "Please upload documents",
          duration: 2,
        });
      }
    }
    setLoading(false);
  };

  const handleClearConversation = async (data = []) => {
    await axiosInstance.post(webAPIs.clearConversation, {
      conversation: data,
    });
    handleResetMessage();
  };

  const handleRemoveConversation = async (index) => {
    let temp_conversation = message;
    temp_conversation.splice(index, 2);
    handleClearConversation(temp_conversation);
  };

  return (
    <div className="w-full bg-mainColor flex flex-col">
      <div className="flex-1 h-[100%] text-textColor flex flex-col items-center text-lg overflow-y-auto">
        {message.map((item, index) => (
          <div
            key={index}
            className={`w-[100%] flex justify-center py-4 ${
              item.role === "assistant" ? "bg-botColor" : "bg-userColor"
            }`}
          >
            <div className="sm:w-[60%] w-full px-2 flex items-center">
              {item.role === "user" ? (
                <div className="flex items-center w-full">
                  <UserOutlined className="text-2xl mr-6" />
                  <ReactMarkdown
                    className=" flex-col flex justify-center items-start place-content-center flex-1 pt-3"
                    children={item.content}
                  />
                  <DeleteOutlined
                    onClick={() => handleRemoveConversation(index)}
                  />
                </div>
              ) : (
                <>
                  <RobotOutlined className="text-2xl mr-6 text-textColor" />
                  <div className="block whitespace-break-spaces text-textColor">
                    {item.content}
                  </div>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="pb-3 pt-3 bottom-7 w-full justify-center flex bg-inputColor border-t border-t-sidebarColor">
        <div className="relative shadow-md w-[70%] bg-textInputColor text-textColor">
          <TextArea
            className="w-full pr-12"
            rows={2}
            onKeyUp={handleKeyUp}
            value={question}
            onChange={(e) => {
              setQuestion(e.target.value);
            }}
            placeholder="Please enter your prompt here"
          />
          <div
            className="cursor-pointer absolute right-0 bottom-1/2 transform translate-y-1/2 mr-3 h-8 w-8 bg-sidebarColor rounded-full flex items-center justify-center"
            onClick={sendRequest}
          >
            <SendOutlined className="text-white" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
