import React, { useEffect, useState, useContext } from 'react';
import PriceCard from '../components/PriceCard';
import axios from 'axios';
import { webAPIs } from '../config/webAPIs';
import { notification } from 'antd';
import { AuthContext } from '../AuthContext';
import axiosInstance from "../config/axiosInstance";

const freeDescription = [
  '1 individual account',
  'Upload documents',
  'Anonymise documents',
  'Get summaries',
  'Create concepts',
  'Write content',
  'Download ouput'
]

const ProDescription = [
  'Invite 2 team members',
  'Transcribe audio',
  'Anonymise transcripts',
  'Design knowledge graph',
  'Scrape content from web',
  'Create SEA campaigns'
]

const AdvancedDescription = [
  'Invite 4 team members'
]

const EnterpriseDescription = [
  'Customized hosting of your data',
  'Regional models available',
  'Unlimited usage',
  'Unlimited team members'
]

const descriptions = [freeDescription, ProDescription, AdvancedDescription, EnterpriseDescription]

const Subscriptions = () => {
  const { user, refreshUserData } = useContext(AuthContext);
  const [monthly, setMonthly] = useState(true);
  const [subscriptions, setSubscriptions] = useState([]);

  const getSubscriptions = async () => {
    try {
      const { data } = await axiosInstance.get(webAPIs.getSubscriptions);
      const subscription = data.data.filter(item => item.name !== 'Free Trial')
        .sort((a, b) => a.id - b.id)
      setSubscriptions(subscription)
    } catch (error) {
      console.error(error);
      notification.error({
        message: "",
        description: error.response?.data?.message || error.message || "An error occurred",
        duration: 5,
      });
    }
  };

  useEffect(() => {
    getSubscriptions();
  }, []);

  return (
    <div className="flex flex-col p-5 items-center gap-5 w-full">
      <div className="flex flex-col items-center">
        <p className="text-[#1E1E1E] font-bold text-base sm:text-3xl">Accelerate Your AI-Powered Marketing</p>
        <p className="text-[#1E1E1E] font-bold text-base sm:text-3xl">Journey with Insight Lab!</p>
      </div>
      <div className="flex items-center gap-3 border p-1 rounded-xl">
        <button className={`w-20 p-2 transition-colors duration-100 ${monthly ? 'text-white bg-[#d8ae5f] rounded-md' : '!bg-white text-black'}`} onClick={() => { setMonthly(true) }}>Monthly</button>
        <button className={`w-20 p-2 transition-colors duration-100 ${monthly ? '!bg-white text-black' : 'text-white bg-[#d8ae5f] rounded-md'}`} onClick={() => { setMonthly(false) }}>Yearly</button>
      </div>
      {subscriptions.length > 0 && (
        <div className="flex items-start gap-3 w-full flex-col md:flex-row flex-1 p-2">
          {subscriptions
            .map((subscription, id) => {
            // Dynamically map subscription name to its description
            const getDescription = (name) => {
              switch (name) {
                case 'Free':
                  return freeDescription;
                case 'Pro':
                  return ProDescription;
                case 'Advanced':
                  return AdvancedDescription;
                case 'Enterprise':
                  return EnterpriseDescription;
                default:
                  return [];
              }
            };

            return (
              <PriceCard
                key={id}
                data={subscription}
                description={getDescription(subscription.name)}
                monthly={monthly}
                activeId={user?.subscriptionId}
                userId={user?.id}
                onReload={refreshUserData}
                setMonthly={setMonthly}
                previousData={subscriptions[id - 1]}
              />
            );
          })}
        </div>
      )}
    </div>
  )
}

export default Subscriptions;