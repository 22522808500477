import axios from 'axios';
import { encryptData, decryptData } from './encryptionUtils';
import { webAPIs } from "./webAPIs";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});

// Request interceptor to encrypt the data
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    if (
      (config.method === 'put' 
        || config.method === 'delete'
        ||config.method === 'post')
      && config.data) {
      config.data = { data: encryptData(JSON.stringify(config.data)) };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to decrypt the data and handle token expiration
axiosInstance.interceptors.response.use(
  (response) => {
    if (response.data && response.data.user) {
      response.data.user = JSON.parse(decryptData(response.data.user));
    }
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // If the error is 401 and we haven't tried to refresh the token yet
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        // Get the refresh token
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) {
          throw new Error('No refresh token available');
        }

        // Call the refresh token endpoint
        const response = await axios.post(webAPIs.refreshToken, {
          refreshToken: refreshToken
        });

        if (response.data.success) {
          // Store the new access token
          localStorage.setItem('accessToken', response.data.accessToken);
          
          // Update the Authorization header
          originalRequest.headers.Authorization = `Bearer ${response.data.accessToken}`;
          
          // Retry the original request
          return axiosInstance(originalRequest);
        }
      } catch (refreshError) {
        // If refresh token fails, redirect to login
        localStorage.clear();
        window.location.href = '/sign-in';
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;