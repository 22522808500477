import React, { useEffect, useState, useContext } from "react";
import {
  notification,
  Table,
  Spin,
  Button,
  Modal,
  Form,
  Input,
  Space,
  Switch,
  Result,
  Select,
} from "antd";
import { AuthContext } from "../AuthContext";
import { webAPIs } from "../config/webAPIs";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../config/axiosInstance";

const PresetPromptsPage = () => {
  const [loading, setLoading] = useState(true);
  const { user, systemRoles } = useContext(AuthContext);
  const navigate = useNavigate();
  const [presetPrompts, setPresetPrompts] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    if (auth) {
      if (user?.role === 3) {
        fetchPresetPrompts();
      }
    } else {
      navigate('/');
    }
  }, [user, navigate]);

  const fetchPresetPrompts = async () => {
    try {
      const response = await axiosInstance.get(webAPIs.getPresetPrompts);
      if (response.data.success) {
        setPresetPrompts(response.data.data);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.error || "Failed to fetch preset prompts",
        duration: 2,
      });
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      width: 200,
    },
    {
      title: "Item Text",
      dataIndex: "itemText",
      key: "itemText",
    },
    {
      title: "Has Open Text Field",
      dataIndex: "hasOpenTextField",
      key: "hasOpenTextField",
      width: 170,
      render: (value) => (value === "yes" ? "Yes" : "No"),
    },
    {
      title: "Open Text ID",
      dataIndex: "openTextId",
      key: "openTextId",
      width: 120,
    },
    {
      title: "Open Text Show",
      dataIndex: "openTextShow",
      key: "openTextShow",
      width: 145,
      render: (value) => (value ? value : ""),
    },
    {
      title: "User Prompt",
      dataIndex: "userPrompt",
      key: "userPrompt",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="small">
          <Button
            className="bg-sidebarColor text-white"
            onClick={() => handleEdit(record)}
          >
            Edit
          </Button>
          <Button
            danger
            type="primary"
            onClick={() => handleDelete(record)}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  const handleEdit = (record) => {
    form.setFieldsValue({
      ...record,
      hasOpenTextField: record.hasOpenTextField === "yes"
    });
    setIsEditing(true);
    setIsModalVisible(true);
  };

  const handleDelete = async (record) => {
    try {
      const response = await axiosInstance.delete(webAPIs.deletePresetPrompt, {
        data: { id: record.id }
      });
      
      if (response.data.success) {
        notification.success({
          description: response.data.message,
          duration: 2,
        });
        fetchPresetPrompts();
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.error || "Failed to delete preset prompt",
        duration: 2,
      });
    }
  };

  const handleModalOk = () => {
    form.validateFields()
      .then(async (values) => {
        try {
          const selectedRole = systemRoles.find(role => role.title === values.role);
          
          const transformedValues = {
            ...values,
            hasOpenTextField: values.hasOpenTextField ? "yes" : "no",
            systemPrompt: selectedRole?.prompt || ''
          };
          
          const endpoint = isEditing ? webAPIs.updatePresetPrompt : webAPIs.createPresetPrompt;
          const method = isEditing ? 'put' : 'post';
          
          const response = await axiosInstance[method](endpoint, {
            ...transformedValues,
          });

          if (response.data.success) {
            notification.success({
              description: response.data.message,
              duration: 2,
            });
            fetchPresetPrompts();
            handleModalCancel();
          }
        } catch (error) {
          notification.error({
            description: error.response?.data?.error || "Operation failed",
            duration: 2,
          });
        }
      });
  };

  const handleModalCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
    setIsEditing(false);
  };

  if (!user || user.role !== 3) {
    return (
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
        extra={
          <Button 
            type="primary"
            className="bg-sidebarColor"
            onClick={() => navigate("/dashboard")}
          >
            Back to Dashboard
          </Button>
        }
      />
    );
  }

  if (loading) {
    return (
      <div className="flex w-full h-full justify-center items-center">
        <Spin size="large" />
      </div>
    );
  }


  return (
    <div className="p-5 w-full flex flex-col gap-5">
      <div className="w-full flex justify-end">
        <Button
          className="w-[20%] flex items-center justify-center text-white bg-sidebarColor"
          onClick={() => setIsModalVisible(true)}
        >
          Add Preset Prompt
        </Button>
      </div>
      
      <div className="overflow-x-auto bg-white">
        <Table
          dataSource={presetPrompts}
          columns={columns}
          pagination={{ defaultPageSize: 10 }}
        />
      </div>

      <Modal
        title={isEditing ? "Edit Preset Prompt" : "Add Preset Prompt"}
        open={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        confirmLoading={loading}
        okButtonProps={{ className: "bg-[#007bff]" }}
      >
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          
          <Form.Item
            name="role"
            label="Role"
            rules={[{ required: true, message: "Please select a role!" }]}
          >
            <Select
              placeholder="Select a role"
              options={systemRoles.map(role => ({
                label: role.role,
                value: role.title
              }))}
            />
          </Form.Item>

          <Form.Item
            name="itemText"
            label="Item Text"
            rules={[{ required: true, message: "Please input the item text!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="hasOpenTextField"
            label="Has Open Text Field"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item
            name="openTextId"
            label="Open Text ID"
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="openTextShow"
            label="Open Text Show"
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="userPrompt"
            label="User Prompt"
            rules={[{ required: true, message: "Please input the user prompt!" }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default PresetPromptsPage;
