import React, { useState, useEffect, useContext, useMemo, useCallback } from "react";
import {
  Table,
  Button,
  Spin,
  Modal,
  Form,
  Input,
  notification,
  Select,
  Slider,
  Checkbox,
  Row,
  Col,
} from "antd";
import { AuthContext } from "../AuthContext";
import { webAPIs } from "../config/webAPIs";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import countryList from "react-select-country-list";
import { useNavigate } from "react-router-dom";
import { formatNumber, subscriptionAlert } from "../utils";
import axiosInstance from "../config/axiosInstance";

const ControlUsers = () => {
  const { user } = useContext(AuthContext);

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [selectedUser, setSelectedUser] = useState(null);
  const [subscriptions, setSubscriptions] = useState([]);
  const options = useMemo(() => countryList().getData(), []);
  const navigate = useNavigate();

  const handleGetUsers = useCallback(async () => {
    if (user) {
      try {
        const response = await axiosInstance.get(webAPIs.getUsers);
        const data = response.data.users.filter((item) =>
          user.role === 3
            ? item !== null && item.role !== 0
            : user.role === 1
              ? item !== null && item.role === 0
                ? item.ownerId === user.id
                : item !== null && item.role !== 0
              : item !== null
        );
        setUsers(data);
      } catch (e) {
        // handle error
      } finally {
        setLoading(false);
      }
    } else {
      console.log('error');
    }
  }, [user]);

  const getSubscriptions = useCallback(async () => {
    try {
      const { data } = await axiosInstance.get(webAPIs.getSubscriptions);
      setSubscriptions(data.data);
    } catch (error) {
      console.error(error);
      notification.error({
        message: "",
        description: error.response?.data?.message || error.message || "An error occurred",
        duration: 5,
      });
    }
  }, []);

  useEffect(() => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    if (auth) {
      handleGetUsers();
      if (user?.role === 3) {
        getSubscriptions();
      }
    } else {
      navigate('/');
    }
  }, [user, handleGetUsers, getSubscriptions, navigate]);


  const deleteUser = async (userId) => {
    Modal.confirm({
      title: 'Delete User',
      content: 'Are you sure you want to delete this user?',
      okButtonProps: { className: "bg-[#007bff]" },
      onOk: async () => {
        try {
          const res = await axiosInstance.post(webAPIs.deleteUser, {
            id: userId,
          });
          if (res.data.success) {
            handleGetUsers();
            notification.success({
              description: "User deleted successfully",
              duration: 1,
            });
          }
        } catch (error) {
          notification.error({
            description: "Failed to delete user",
            duration: 1,
          });
        }
      }
    });
  };

  const resetPassword = async (userEmail) => {
    Modal.confirm({
      title: 'Reset Password',
      content: 'Are you sure you want to reset password?',
      okButtonProps: { className: "bg-[#007bff]" },
      onOk: async () => {
        try {
          const res = await axiosInstance.post(webAPIs.resetPassword, {
            email: userEmail,
          });
          if (res.data.success) {
            handleGetUsers();
            notification.success({
              description: "Password has been reset to 12345678",
              duration: 5,
            });
          }
        } catch (error) {
          notification.error({
            description: "Failed to reset password",
            duration: 1,
          });
        }
      }
    });
  };

  const showModal = (user = null, role = null) => {
    if (user) {
      form.setFieldsValue({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phoneNumber: user.phoneNumber,
        street: user.street,
        postalCode: user.postalCode,
        city: user.city,
        country: user.country,
        status: user.status,
        companyName: user.companyName,
        role: user.role,
        modelServer: user.modelServer,
        temp: user.temp,
        systemPrompt: user.systemPrompt,
        subscription: user.subscriptionId,
      });
      setSelectedUser(user);
    } else {
      const roles = setRoles(role);
      const teamMemberRole = roles.find(role => role.value === 0);
      const defaultRole = teamMemberRole ? 0 : roles[0]?.value;
      form.setFieldsValue({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        street: "",
        postalCode: "",
        city: "",
        country: "",
        status: true,
        companyName: "",
        role: defaultRole,
        modelServer: "OpenAI",
        temp: 0.1,
        systemPrompt: 'You are a qualitative market researcher with the task of analyzing and summarizing several interviews conducted between an interviewer and a respondent about a specific topic, each interview beginning with "******" and ending with "******"',
        subscription: 2,
      });
    }
    setIsModalVisible(true);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        form.resetFields();
        setIsModalVisible(false);
        // Call your API to add a user
        try {
          let response;
          if (selectedUser) {
            response = await axiosInstance.post(webAPIs.changeUser, {
              user: {
                ...values,
                id: selectedUser.id,
              },
            });
          } else {
            response = await axiosInstance.post(webAPIs.inviteUser, {
              user: { ...values },
            });
          }
          if (response.data.success) {
            notification.success({
              description: response.data.message,
              duration: 1,
            });
          }
        } catch (e) {
          // handle error
          notification.error({
            description: e.response?.data?.message || e.message || "An error occurred",
            duration: 1,
          });
        } finally {
          handleGetUsers();
          setLoading(false);
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const columns = [
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      fixed: "left",
      render: (createdAt) => {
        const date = new Date(createdAt);
        return date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        });
      },
      sorter: {
        compare: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      },
      width: 200,
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      width: 100,
      key: "firstName",
      fixed: "left",
      sorter: {
        compare: (a, b) => (a.firstName || '').localeCompare(b.firstName || ''),
      },
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      width: 100,
      key: "lastName",
      fixed: "left",
      sorter: {
        compare: (a, b) => (a.lastName || '').localeCompare(b.lastName || ''),
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: {
        compare: (a, b) => (a.email || '').localeCompare(b.email || ''),
      },
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      sorter: {
        compare: (a, b) => (a.phoneNumber || '').localeCompare(b.phoneNumber || ''),
      },
    },
    {
      title: "Postal Code",
      dataIndex: "postalCode",
      sorter: {
        compare: (a, b) => (a.postalCode || '').localeCompare(b.postalCode || ''),
      },
      width: 200,
    },
    {
      title: "Street",
      dataIndex: "street",
      sorter: {
        compare: (a, b) => (a.street || '').localeCompare(b.street || ''),
      },
    },
    {
      title: "City",
      dataIndex: "city",
      sorter: {
        compare: (a, b) => (a.city || '').localeCompare(b.city || ''),
      },
    },
    {
      title: "Country",
      dataIndex: "country",
      sorter: {
        compare: (a, b) => (a.country || '').localeCompare(b.country || ''),
      },
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      sorter: {
        compare: (a, b) => (a.companyName || '').localeCompare(b.companyName || ''),
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (status ? "True" : "False"),
      sorter: {
        compare: (a, b) => (a.status === b.status ? 0 : a.status ? 1 : -1),
      },
    },
    {
      title: "Permissions Role",
      dataIndex: "role",
      render: (role) =>
        role === 0
          ? "Account"
          : role === 1
            ? "AccountOwner"
            : role === 2
              ? "Admin"
              : "SuperAdmin",
      sorter: {
        compare: (a, b) => (a.role || 0) - (b.role || 0),
      },
    },
    {
      title: "System Prompt",
      dataIndex: "systemPrompt",
      render: (systemPrompt) => (
        <p className="truncate w-40 block text-base">{systemPrompt}</p>
      ),
      sorter: {
        compare: (a, b) => (a.systemPrompt || '').localeCompare(b.systemPrompt || ''),
      },
    },
    {
      title: "Temperature",
      dataIndex: "temp",
      sorter: {
        compare: (a, b) => (a.temp || 0) - (b.temp || 0),
      },
    },
    ...(user && user.role === 3
      ? [
        {
          title: "Subscription Name",
          dataIndex: "subscriptionName",
          sorter: {
            compare: (a, b) => (a.subscriptionName || '').localeCompare(b.subscriptionName || '')
          }
        },
        {
          title: "Advanced Rights",
          dataIndex: "advancedRights",
          render: (status) => (status ? "True" : "False"),
          sorter: {
            compare: (a, b) => (a.advancedRights === b.advancedRights ? 0 : a.advancedRights ? 1 : -1)
          }
        },
        {
          title: "Server",
          dataIndex: "modelServer",
          sorter: {
            compare: (a, b) => (a.modelServer || '').localeCompare(b.modelServer || '')
          }
        },
        {
          title: "Model",
          dataIndex: "modelName",
          sorter: {
            compare: (a, b) => (a.modelName || '').localeCompare(b.modelName || '')
          }
        },
        {
          title: "Prompt Price / 1M",
          dataIndex: "promptPrice",
          sorter: {
            compare: (a, b) => (parseFloat(a.promptPrice) || 0) - (parseFloat(b.promptPrice) || 0)
          }
        },
        {
          title: "Completion Price / 1M",
          dataIndex: "completionPrice",
          sorter: {
            compare: (a, b) => (parseFloat(a.completionPrice) || 0) - (parseFloat(b.completionPrice) || 0)
          }
        },
        {
          title: "Context Length",
          dataIndex: "contextLength",
          render: (length) => formatNumber(length),
          sorter: {
            compare: (a, b) => (a.contextLength || 0) - (b.contextLength || 0)
          }
        },
        {
          title: "Total Tokens All Time",
          dataIndex: "totalTokensAllTime",
          render: (token) => formatNumber(token),
          sorter: {
            compare: (a, b) => (a.totalTokensAllTime || 0) - (b.totalTokensAllTime || 0)
          }
        },
        {
          title: "Total Tokens Last 30 Days",
          dataIndex: "totalTokensLast30Days",
          render: (token) => formatNumber(token),
          sorter: {
            compare: (a, b) => (a.totalTokensLast30Days || 0) - (b.totalTokensLast30Days || 0)
          }
        },
        {
          title: "Token Costs",
          dataIndex: "tokenCosts",
          sorter: {
            compare: (a, b) => (parseFloat(a.tokenCosts) || 0) - (parseFloat(b.tokenCosts) || 0)
          }
        },
        {
          title: "Token Costs 30 Days",
          dataIndex: "tokenCosts30Days",
          sorter: {
            compare: (a, b) => (parseFloat(a.tokenCosts30Days) || 0) - (parseFloat(b.tokenCosts30Days) || 0)
          }
        },
      ]
      : []),
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 200,
      render: (text, record) => (
        <div>
          <Button 
            className="text-white bg-sidebarColor hover:bg-[#836a49]"
            onClick={() => showModal(record)}
          >
            Change
          </Button>
          <Button 
            className="text-white bg-sidebarColor hover:bg-[#836a49]"
            onClick={() => deleteUser(record.id)}
          >
            Delete
          </Button>
          <Button 
            className="text-white bg-sidebarColor hover:bg-[#836a49]"
            onClick={() => resetPassword(record.email)}
          >
            Reset Password
          </Button>
        </div>
      ),
    },
  ];

  const setRoles = (userRole) => {
    const allRoles = [
      { label: "Team Member", value: 0 },
      { label: "Team Account Owner", value: 1 },
      { label: "Admin", value: 2 },
      { label: "Super Admin", value: 3 },
    ];
    if (userRole === 3) {
      return allRoles.filter((item) => item.value !== 0);
    } else if (userRole > 1) {
      return allRoles.filter(
        (role) =>
          allRoles.findIndex((r) => r.value === userRole) <=
          allRoles.findIndex((r) => r.value === role.value)
      );
    } else {
      return allRoles.filter((item) => item.value <= userRole);
    }
  };

  const handleAddUser = () => {
    // const length = users.length;
    // switch (user.subscriptionId) {
    //   case 1:
    //     if (length > 2) {
    //       subscriptionAlert();
    //       return;
    //     }
    //     break;
    //   case 2:
    //     if (length > 1) {
    //       subscriptionAlert();
    //       return;
    //     }
    //     break;
    //   case 3:
    //     if (length > 2) {
    //       subscriptionAlert();
    //       return;
    //     }
    //     break;
    //   case 4:
    //     if (length > 4) {
    //       subscriptionAlert();
    //       return;
    //     }
    //     break;
    //   default:
    //     break;
    // }
    setSelectedUser(null);
    showModal(null, user?.role);
  }

  const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  return (
    <div className="p-5 w-full flex flex-col gap-5">
      <div className="w-full flex justify-end">
        <Button
          className="w-[20%] flex items-center justify-center text-white bg-sidebarColor"
          onClick={() => {
            handleAddUser();
          }}
        >
          {user && user.role > 1 ? "Add a Team Member" : "Invite a Team Member"}
        </Button>
      </div>
      {loading ? (
        <Spin className="w-full h-screen" />
      ) : (
        <div className="overflow-x-auto bg-white">
          <Table
            dataSource={users}
            columns={columns}
            rowKey="id"
            pagination={{ defaultPageSize: 10 }}
            onChange={onChange}
            className="control-users-table"
          />
        </div>
      )}
      <Modal
        okButtonProps={{ className: "bg-[#007bff]" }}
        title="Add a Team Member"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={() => setIsModalVisible(false)}
        width="80%"
      >
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          form={form}
          autoComplete="off"
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please input user name!",
                  },
                ]}
              >
                <Input autoComplete="off" />
              </Form.Item>
              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Please input user name!",
                  },
                ]}
              >
                <Input autoComplete="off" />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Please input email!",
                  },
                ]}
              >
                <Input autoComplete="off" />
              </Form.Item>
              <Form.Item
                label="Street"
                name="street"
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="City"
                name="city"
                rules={[
                  {
                    required: true,
                    message: "Please input user city!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Country"
                name="country"
                rules={[
                  {
                    required: true,
                    message: "Please select country!",
                  },
                ]}
              >
                <Select options={options} />
              </Form.Item>
              <Form.Item
                label="Postal Code"
                name="postalCode"
                rules={[
                  {
                    required: true,
                    message: "Please input postalCode!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Phone"
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Please input phone number!",
                  },
                ]}
              >
                <PhoneInput placeholder="Enter phone number" />
              </Form.Item>
              <Form.Item
                label="Company Name"
                name="companyName"
              >
                <Input placeholder="Please input CompanyName" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Active account" name="status" valuePropName="checked">
                <Checkbox />
              </Form.Item>
              {user && user.role && (
                <Form.Item
                  label="Permissions Role"
                  name="role"
                  rules={[
                    {
                      required: true,
                      message: "Please input role!",
                    },
                  ]}
                >
                  <Select
                    // value={role}
                    options={setRoles(user.role).map((role) => ({
                      label: role.label,
                      value: role.value,
                    }))}
                  />
                </Form.Item>
              )}
              {user?.role === 3 && <Form.Item 
                name={"modelServer"}
                label="AI Server"
              >
                <Select
                  options={[
                    { label: "Open AI", value: "OpenAI" },
                    { label: "Azure AI", value: "AzureAI" }
                  ]}
                />
              </Form.Item>}
              <Form.Item name="temp" label="Temperature">
                <Slider
                  min={0}
                  max={1}
                  step={0.1}
                  marks={{
                    0: "0",
                    0.2: "0.2",
                    0.4: "0.4",
                    0.6: "0.6",
                    0.8: "0.8",
                    1: "1",
                  }}
                />
              </Form.Item>
              <Form.Item
                label="System Prompt"
                name="systemPrompt"
                rules={[
                  {
                    required: true,
                    message: "Please input systemPrompt!",
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
              {user?.role === 3 &&
                <Form.Item
                  label="Subscription"
                  name="subscription"
                  rules={[
                    {
                      required: true,
                      message: "Please input subscription",
                    }
                  ]}
                >
                  <Select
                    options={subscriptions.map((sub) => ({
                      label: sub.name,
                      value: sub.id
                    }))}
                  />
                </Form.Item>}
              {/* {!selectedUser && (
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input password!",
                    },
                  ]}
                >
                  <Input.Password autoComplete="off" />
                </Form.Item>
              )} */}
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default ControlUsers;
