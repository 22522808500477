import React, { useContext } from "react";
import { Input, Checkbox, Slider, InputNumber, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../AuthContext";

const { TextArea } = Input;

const AdvancedSetting = () => {
  const {
    adminPrompt,
    setAdminPrompt,
    setChecked,
    checked,
    inputValue,
    setInputValue,
    setKSystemPrompt,
    kSystemPrompt,
    setKUserPrompt,
    kUserPrompt,
    setKFontSize,
    kFontSize,
  } = useContext(AuthContext);
  const navigate = useNavigate();

  const onTempSliderChange = (value) => {
    if (isNaN(value)) {
      return;
    }
    setInputValue(value);
  };

  return (
    <div className="w-full flex flex-col p-5 gap-5">
      <span className="w-full bg-sidebarColor text-white p-2 rounded-sm">
        Advanced Settings
      </span>
      <div className="sm:px-16 w-full flex flex-col border-b border-sidebarColor gap-2 relative">
        <div className="absolute sm:right-16 right-0">
          <Button
            className="text-white bg-sidebarColor hover:bg-[#836a49]"
            onClick={() => {
              navigate("/dashboard/admin/account");
            }}
          >
            Account Settings
          </Button>
        </div>
        <span className="p-2">System Prompt</span>
        <TextArea
          style={{
            marginBottom: 24,
            paddingRight: 16,
            paddingLeft: 16,
            color: "#3F4A49",
          }}
          rows={6}
          onChange={(e) => setAdminPrompt(e.target.value)}
          className="bg-textInputColor shadow-md"
          value={adminPrompt}
        />
        {/* <span className="pt-2">Change System Variables</span>
        <div className="mb-5 flex-1 border border-sidebarColor rounded-md">
          <Steps
            systemRole={systemRoles.find((sys) => sys.id === user?.systemRole)}
          />
        </div> */}
        <Checkbox onChange={() => setChecked(!checked)} checked={checked}>
          Document Delimiter ("******")
        </Checkbox>
        <div className="flex w-full gap-5 pb-3">
          <div className="w-full items-center justify-center flex flex-col">
            <Slider
              min={0}
              max={1}
              className="w-full"
              onChange={onTempSliderChange}
              value={typeof inputValue === "number" ? inputValue : 0}
              step={0.1}
            />
            <span>Temperature</span>
          </div>
          <InputNumber
            min={0}
            max={1}
            value={inputValue}
            className="bg-transparent border border-black h-1/2 items-center flex mt-1"
            onChange={onTempSliderChange}
            step={0.1}
          />
        </div>
      </div>
      <span className="w-full bg-sidebarColor text-white p-2 rounded-sm">
        K-Graph
      </span>
      <div className="px-16 w-full flex flex-col gap-2 py-3">
        <div>
          <span className="p-2">System Prompt</span>
          <TextArea
            style={{
              marginBottom: 24,
              paddingRight: 16,
              paddingLeft: 16,
              color: "#3F4A49",
            }}
            rows={4}
            onChange={(e) => setKSystemPrompt(e.target.value)}
            className="bg-textInputColor shadow-md"
            value={kSystemPrompt}
          />
        </div>
        <div>
          <span className="p-2">User Prompt</span>
          <TextArea
            style={{
              marginBottom: 24,
              paddingRight: 16,
              paddingLeft: 16,
              color: "#3F4A49",
            }}
            rows={4}
            onChange={(e) => setKUserPrompt(e.target.value)}
            className="bg-textInputColor shadow-md"
            value={kUserPrompt}
          />
        </div>
        <div>
          <span className="p-2">Font Size</span>
          <InputNumber
            min={1}
            onChange={(e) => setKFontSize(e)}
            className="bg-textInputColor shadow-md"
            value={kFontSize}
          />
        </div>
      </div>
    </div>
  );
};

export default AdvancedSetting;
