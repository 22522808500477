import React from "react";
import { Button, notification, Modal } from "antd";
import { CheckCircleOutlined } from '@ant-design/icons';
import axios from "axios";
import { webAPIs } from "../config/webAPIs";
import { formatNumber } from "../utils";
import axiosInstance from "../config/axiosInstance";

const PriceCard = ({ data, description, monthly, activeId, userId, onReload, setMonthly, previousData }) => {

  const planMessages = {
    2: "Benefit from the most powerful gen AI model and use up to 500K tokens per month for individual usage.",
    3: "Benefit from the most powerful gen AI model and use up to 2M tokens per month. Share your insights with up to 2 team members.",
    4: "Benefit from the most powerful gen AI model and use up to 6M tokens per month. Share your insights with up to 4 team members.",
    5: "Benefit from customized hosting of your data, regional models available, unlimited usage, unlimited team members.",
  };

  const handleClick = async () => {
    if (data?.id === 5) {
      const mailto = 'mailto:info@insight-lab.ai'
      window.location.href = mailto;
    } else {
      Modal.confirm({
        title: `Switch to the ${data.name} Plan`,
        content: (
          <div>
            <p className="mb-4">{planMessages[data.id] || "Are you sure you want to change subscriptions?"}</p>
            <p className="mb-4">These changes will be reflected from the next billing cycle:</p>
            <p className="mb-4">
              <strong>Plan: </strong>{data.name}
            </p>
            <p className="mb-4">
              <strong>Billing period: </strong>{monthly ? 'monthly' : 'yearly'}
            </p>
            {monthly ? (
                <p className="mb-4">
                  <strong>Price: </strong>€ {data.priceMonthly} / monthly
                </p>
              )
              : (
                <p className="mb-4">
                  <strong>Price: </strong>€ {data.priceYearly} / monthly
                </p>
              )
            }
          </div>
        ),
        okText: 'Yes, Change',
        cancelText: 'No, Cancel',
        okButtonProps: {
          style: {
            backgroundColor: '#4CAF50',
            color: '#FFFFFF',
            border: 'none',
          },
        },
        cancelButtonProps: {
          style: {
            backgroundColor: '#F44336',
            color: '#FFFFFF',
            border: 'none',
          },
        },
        onOk: async () => {
          let priceId = '';
          
          // Mapping for price IDs based on subscription data
          const priceMapping = {
            3: monthly ? process.env.REACT_APP_PRO_MONTHLY_PRICE_ID : process.env.REACT_APP_PRO_YEARLY_PRICE_ID,
            4: monthly ? process.env.REACT_APP_ADVANCED_MONTHLY_PRICE_ID : process.env.REACT_APP_ADVANCED_YEARLY_PRICE_ID,
          };
  
          priceId = priceMapping[data.id];
  
          try {
            const response = await axiosInstance.post(webAPIs.changeSubscription, { subscriptionId: data.id, priceId });
            if (response.data.url) {
              window.location.href = response.data.url;
            } else {
              notification.success({
                message: "Subscription Update Successful",
                description: "Your subscription has been successfully updated. We will refresh the data shortly.",
                duration: 5,
              });
  
              const reloadTimeout = setTimeout(() => {
                onReload();
              }, 2000);
              return () => clearTimeout(reloadTimeout);
            }
          } catch (error) {
            console.error(error);
            const errorMessage = error.response?.data?.error || error.message || "An unexpected error occurred.";
            notification.error({
              message: "",
              description: errorMessage,
              duration: 5,
            });
          }
        },
      });
    }
  };

  return (
    <div className={`flex flex-col border md:w-1/4 w-full h-full max-h-[600px] container p-8 gap-4 rounded-2xl md:overflow-y-auto ${data.id === activeId ? 'border-[#d8ae5f] border-2' : ''}`}>
      <p className="text-[#1E1E1E] font-semibold text-2xl">{data?.name}</p>
      {data?.name !== 'Enterprise' ?
        <div className="flex gap-2 items-baseline h-14">
          <span className="font-bold text-6xl">€{monthly ? data?.priceMonthly : data?.priceYearly}</span>
          <span className="font-normal">/ {'monthly'}</span>
        </div> :
        <p className="font-thin text-4xl h-14">Let's Talk !</p>
      }
      <div className="text-center mt-4">
        {data?.name === 'Enterprise' ?
          <p >Custom Pricing</p>
          : data?.name !== 'Free' ?
            <p >Billed {monthly ? 'monthly' : "yearly"}. Pay <span className="font-normal text-[#d8ae5f] hover:cursor-pointer" onClick={() => { setMonthly(!monthly) }}>{monthly ? 'monthly' : "yearly"}</span></p> :
            <p >No credit card required</p>
        }
      </div>
      <Button className="text-white" onClick={handleClick} disabled={data.id === activeId}>
        {data?.name !== 'Enterprise' ? 'Start' : 'Book demo'}
      </Button>
      {data?.name !== 'Free' && previousData?.name && <span className="uppercase font-normal">EVERYTHING IN {previousData?.name} PLUS </span>}
      {description.map((des, id) => <div className="flex gap-2" key={id}><CheckCircleOutlined className="text-[#5FD893]" />{des}</div>)}
      {data?.name !== 'Enterprise' && <span className="flex-1 items-end flex justify-center">Up to {formatNumber(data?.maxToken)} tokens</span>}
    </div>
  )
}

export default PriceCard;