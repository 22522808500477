import React, { useEffect, useState, useContext, useCallback, useMemo } from "react";
import {
  Radio,
  Input,
  Button,
  Spin,
  Select,
  notification
} from "antd";
import { AuthContext } from "../AuthContext";
import { webAPIs } from "../config/webAPIs";
import { useNavigate } from "react-router-dom";
import { SendOutlined, UserOutlined, RobotOutlined, DeleteOutlined } from "@ant-design/icons";
import ReactMarkdown from "react-markdown";
import axiosInstance from "../config/axiosInstance";

const QuickPromptPage = () => {
  const [loading, setLoading] = useState(true);
  const [presetPrompts, setPresetPrompts] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [openTextValues, setOpenTextValues] = useState({});
  const [outputLanguage, setOutputLanguage] = useState("English");
  const { 
    user, 
    files, 
    setLoading: setGlobalLoading, 
    checked, 
    adminPrompt,
    systemRoles
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);

  const userRoleTitle = useMemo(() => {
    const userRole = systemRoles.find(role => role.id === user?.systemRole);
    return userRole?.title || 'Loading...';
  }, [systemRoles, user?.systemRole]);

  const fetchPreviousConversation = useCallback(async () => {
    if (user) {
      try {
        const response = await axiosInstance.get(webAPIs.getConversation);
        if (response.data.success && response.data.result.conversation) {
          setMessages(response.data.result.conversation);
        }
      } catch (error) {
        console.error('Error fetching conversation:', error);
        notification.error({
          description: "Failed to load previous conversations",
          duration: 2,
        });
      }
    }
  }, [user]);

  const fetchPresetPrompts = useCallback(async () => {
    try {
      const response = await axiosInstance.get(webAPIs.getPresetPrompts);
      if (response.data.success) {
        setPresetPrompts(response.data.data);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.error || "Failed to fetch preset prompts",
        duration: 2,
      });
    }
  }, [user]);

  useEffect(() => {
    const loadInitialData = async () => {
      const auth = JSON.parse(localStorage.getItem("auth"));
      if (auth && user) {
        try {
          setLoading(true);
          await Promise.all([
            fetchPresetPrompts(),
            fetchPreviousConversation()
          ]);
        } catch (error) {
          console.error('Error loading initial data:', error);
        } finally {
          setLoading(false);
        }
      } else if (!auth) {
        navigate('/');
      }
    };

    loadInitialData();
  }, [navigate, user, fetchPresetPrompts, fetchPreviousConversation]);

  // useEffect(() => {
  //   // Clear messages when files change
  //   setMessages([]);
  // }, [files]);

  const handleRemoveConversation = async (index) => {
    const updatedMessages = [...messages];
    updatedMessages.splice(index, 2);
    try {
      await axiosInstance.post(webAPIs.clearConversation, {
        conversation: updatedMessages,
      });
      setMessages(updatedMessages);
    } catch (error) {
      console.error('Error removing conversation:', error);
    }
  };

  const handleRoleSelect = (role) => {
    setSelectedRole(role);
    // Reset open text values when changing role
    setOpenTextValues({});
  };

  const handleOpenTextChange = (id, value) => {
    setOpenTextValues(prev => ({
      ...prev,
      [id]: value
    }));
  };

  const handleCreate = async () => {
    if (!selectedRole) {
      notification.warning({
        message: "Please select an analysis type",
        duration: 2
      });
      return;
    }

    const missingFields = selectedRole.hasOpenTextField === "yes" && 
      (!openTextValues[selectedRole.openTextId] || 
       openTextValues[selectedRole.openTextId].trim() === '');

    if (missingFields) {
      notification.warning({
        message: "Please fill in all required fields",
        duration: 2
      });
      return;
    }

    if (!files || files.length === 0) {
      notification.warning({
        description: "Please upload documents",
        duration: 2,
      });
      return;
    }

    setGlobalLoading(true);
    try {
      let prompt = selectedRole.userPrompt;
      if (selectedRole.hasOpenTextField === "yes") {
        prompt = prompt.replace(
          `{${selectedRole.openTextId}}`, 
          openTextValues[selectedRole.openTextId] || ''
        );
      }

      // Modify adminPrompt to include selected language
      let modifiedAdminPrompt = adminPrompt;
      if (modifiedAdminPrompt.includes("Please write in")) {
        modifiedAdminPrompt = modifiedAdminPrompt.replace(
          /Please write in [a-zA-Z]+\./,
          `Please write in ${outputLanguage}.`
        );
      } else {
        modifiedAdminPrompt = `${modifiedAdminPrompt} Please write in ${outputLanguage}.`;
      }

      const newUserMessage = {
        role: "user",
        content: prompt,
      };

      const updatedMessages = [...messages, newUserMessage];
      setMessages(updatedMessages);

      const response = await axiosInstance.post(
        webAPIs.chat,
        {
          prompt: prompt,
          systemPrompt: modifiedAdminPrompt,
          checked: checked,
          temp: 0,
        },
        {
          timeout: 1000000,
        }
      );

      const newAssistantMessage = {
        role: "assistant",
        content: response.data.result,
      };

      const finalMessages = [...updatedMessages, newAssistantMessage];
      setMessages(finalMessages);

      // await axios.post(webAPIs.clearConversation, {
      //   user: user.id,
      //   conversation: finalMessages,
      // });

    } catch (error) {
      notification.error({
        description: error.response?.data?.message || "Server Error",
        duration: 2,
      });
    } finally {
      setGlobalLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex w-full h-full justify-center items-center">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="flex h-full">
      {/* Left Column - Prompt Selection */}
      <div className="w-1/2 p-5 border-r border-gray-200 flex flex-col">
        <div className="overflow-y-auto">
          <div className="mb-8">
            <h2 className="text-xl font-semibold mb-4">
              Quick Prompts for Role: {userRoleTitle}
            </h2>
            <Radio.Group 
              onChange={(e) => handleRoleSelect(e.target.value)}
              value={selectedRole}
              className="flex flex-col gap-3"
            >
              {presetPrompts.map(prompt => (
                <Radio key={prompt.id} value={prompt}>
                  {prompt.itemText}
                </Radio>
              ))}
            </Radio.Group>
          </div>

          {selectedRole?.hasOpenTextField && selectedRole?.openTextShow && (
            <div className="mb-8">
              <Input.TextArea
                placeholder={selectedRole.openTextShow}
                value={openTextValues[selectedRole.openTextId] || ''}
                onChange={(e) => handleOpenTextChange(selectedRole.openTextId, e.target.value)}
                rows={4}
              />
            </div>
          )}
        </div>

        <div className="pt-4 border-t">
          <div className="mb-2">
            <label className="text-base font-medium">Output Language</label>
          </div>
          <Select
            className="w-full mb-4"
            value={outputLanguage}
            onChange={setOutputLanguage}
            options={[
              { label: "English (default)", value: "English" },
              { label: "Arabic", value: "Arabic" },
              { label: "Chinese", value: "Chinese" },
              { label: "French", value: "French" },
              { label: "German", value: "German" },
              { label: "Italian", value: "Italian" },
              { label: "Japanese", value: "Japanese" },
              { label: "Portuguese", value: "Portuguese" },
              { label: "Spanish", value: "Spanish" },
              { label: "Turkish", value: "Turkish" },
            ]}
          />

          <Button 
            type="primary"
            className="w-full bg-blue-500 hover:bg-blue-600 flex items-center justify-center gap-2"
            onClick={handleCreate}
            icon={<SendOutlined />}
          >
            Create
          </Button>
        </div>
      </div>

      {/* Right Column - Results */}
      <div className="w-1/2 bg-mainColor flex flex-col">
        <div className="flex-1 h-[100%] text-textColor flex flex-col items-center text-lg overflow-y-auto">
          {messages.map((item, index) => (
            <div
              key={index}
              className={`w-[100%] flex justify-center py-4 ${
                item.role === "assistant" ? "bg-botColor" : "bg-userColor"
              }`}
            >
              <div className="sm:w-[60%] w-full px-2 flex items-center">
                {item.role === "user" ? (
                  <div className="flex items-center w-full">
                    <UserOutlined className="text-2xl mr-6" />
                    <ReactMarkdown
                      className="flex-col flex justify-center items-start place-content-center flex-1 pt-3"
                      children={item.content}
                    />
                    <DeleteOutlined
                      onClick={() => handleRemoveConversation(index)}
                    />
                  </div>
                ) : (
                  <>
                    <RobotOutlined className="text-2xl mr-6 text-textColor" />
                    <div className="block whitespace-break-spaces text-textColor">
                      {item.content}
                    </div>
                  </>
                )}
              </div>
            </div>
          ))}
          {messages.length === 0 && (
            <div className="text-gray-400 text-center mt-4">
              Results will appear here after analysis
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuickPromptPage; 