import React, { useState, useContext, useEffect } from "react";
import { InputType, RadioType, DropdownType } from "../types";
import { Button, notification } from "antd";
import { AuthContext } from "../AuthContext";
import axios from "axios";
import { webAPIs } from "../config/webAPIs";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../config/axiosInstance";

const Steps = ({ systemRole }) => {
  const [systemPrompt, setSystemPrompt] = useState(
    systemRole?.systemPrompt || ""
  );
  const { user, loggedIn, handleResetMessage } = useContext(AuthContext);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const { updateUser } = useContext(AuthContext);
  const [updateText, setUpdateText] = useState("");
  const [isFinish, setIsFinish] = useState(false);

  const navigate = useNavigate();

  const handleNext = (insertVariable) => {
    let shouldProceed = true;
    try {
      if (updateText === "" && insertVariable.type === "input") {
        notification.error({
          description: "Input Text is required",
          duration: 2,
        });
        shouldProceed = false;
        return;
      } else if (
        updateText === "" &&
        (insertVariable.type === "radio" || insertVariable.type === "dropdown")
      ) {
        replaceVariablesWithValues(
          systemPrompt,
          insertVariable.label,
          insertVariable.options[0].value
        );
      } else {
        replaceVariablesWithValues(
          systemPrompt,
          insertVariable.label,
          updateText
        );
        setUpdateText("");
      }
    } catch (error) {
      notification.error({
        description: error,
        duration: 2,
      });
    } finally {
      if (shouldProceed) {
        if (currentStepIndex + 1 < systemRole?.insertVariableIds?.length) {
          setCurrentStepIndex((prevStepIndex) => prevStepIndex + 1);
        } else {
          setIsFinish(true);
        }
      }
    }
  };

  const replaceVariablesWithValues = (systemPrompt, key, value) => {
    const updateSystemPrompt = systemPrompt.replace(
      new RegExp(`\\{${key}\\}`, "g"),
      value
    );
    setSystemPrompt(updateSystemPrompt);
  };

  const handlePrev = () => {
    setCurrentStepIndex((prevStepIndex) => prevStepIndex - 1);
  };

  const setInsertVariableData = (insertVariable, val) => {
    setUpdateText(val);
    
    // replaceVariablesWithValues(systemPrompt, insertVariable.label, val);
  };

  const renderStep = (insertVariable) => {
    switch (insertVariable?.type) {
      case "input":
        return (
          <InputType
            insertVariable={insertVariable}
            setData={(val) => setInsertVariableData(insertVariable, val)}
          />
        );
      case "radio":
        return (
          <RadioType
            insertVariable={insertVariable}
            setData={(val) => setInsertVariableData(insertVariable, val)}
          />
        );
      case "dropdown":
        return (
          <DropdownType
            insertVariable={insertVariable}
            setData={(val) => setInsertVariableData(insertVariable, val)}
          />
        );
      default:
        return null;
    }
  };

  const onFinish = async () => {
    if (loggedIn) {
      try {
        const response = await axiosInstance.post(webAPIs.roleandvariables, {
          systemRole: systemRole?.id,
          systemPrompt,
        });
        if (response.data.success) {
          const newUser = {
            ...user,
            systemRole: systemRole?.id,
          };
          updateUser(newUser);
          navigate("/dashboard");
          setCurrentStepIndex(0);
          setSystemPrompt(systemRole?.systemPrompt);
          handleResetMessage();
        }
      } catch (error) {
        console.log(error);
        notification.error({
          description: error.message,
          duration: 2,
        });
      }
    } else {
      navigate("/sign-in", {
        state: { systemPrompt, systemRole: systemRole.id },
      });
    }
  };

  useEffect(() => {
    if (isFinish) {
      onFinish();
      setIsFinish(false);
    }
  // eslint-disable-next-line
  }, [isFinish]);
  return (
    <div className="bg-white max-w-2xl mx-auto my-8 rounded-md flex flex-col">
      {renderStep(systemRole?.insertVariableIds?.[currentStepIndex])}
      <div className="flex p-4">
        <div className="flex justify-between w-full">
          <Button
            className={`py-3 flex items-center justify-center bg-[#4d4d4f] text-white ${
              currentStepIndex > 0 ? "visible" : "invisible"
            }`}
            onClick={() => handlePrev()}
          >
            Prev
          </Button>

          <Button
            className={`py-3 flex items-center justify-center bg-[#4d4d4f] text-white`}
            onClick={() =>
              handleNext(systemRole?.insertVariableIds?.[currentStepIndex])
            }
          >
            {currentStepIndex + 1 < systemRole?.insertVariableIds?.length
              ? "Next"
              : "Finish"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Steps;
